<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" class="pb-0">

                <v-card
                    :elevation="1"
                    class="mb-2 pt-1"
                    color="blue-grey lighten-5"
                >
                    <v-form @submit.prevent="searchAppointments" class="custom-form">
                        <v-row class="ml-0 px-1" dense>
                        <v-col cols="3">
                            <v-combobox
                                label="Doctor"
                                dense
                                outlined
                                hide-details
                                @focus="$store.dispatch('doctor/getDoctors')"
                                :loading="$store.getters['doctor/loading']"
                                :items="[{id: null, display_text: 'All'}].concat($store.getters['doctor/doctors'])"
                                v-model="selectedDoctor"
                                item-text="display_text"
                                item-value="id"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                        <v-col cols="3">
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="filter.fromDate"
                                        v-bind="attrs"
                                        v-on="on"
                                        label="From Date"
                                    >
                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="filter.fromDate" :rules="[v => !!v]"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="filter.toDate"
                                        v-bind="attrs"
                                        v-on="on"
                                        label="To Date"
                                    >
                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="filter.toDate" :rules="[v => !!v]"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col md="3">
                            <v-btn type="submit" color="info" small style="margin-top:-1px" class="mr-1">Search</v-btn>
                            <v-btn @click="resetForm" color="deep-orange" dark small style="margin-top:-1px">Clear</v-btn>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-card>

                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="appointmentHeaders"
                    :items="$store.getters['appointment/appointments']"
                    :search="appointmentSearch"
                    :loading="$store.getters['appointment/loading']"
                    loading-text="Loading Appointments..."
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >Appointment List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Appointment"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="appointmentSearch"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <!-- <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="printInvoice(item.id)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-printer</v-icon
                                >
                            </template>
                            <span>Print</span>
                        </v-tooltip>
                    </template> -->
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "AppointmentEntry",
    data: () => ({
        appointmentSearch: '',
        appointmentHeaders: [
            { text: "SL", value: "sl" },
            { text: "Date", value: "appointment_date" },
            { text: "Patient Name", value: "patient.name" },
            { text: "Mobile", value: "patient.mobile" },
            { text: "Doctor Name", value: "doctor.name" },
            // { text: "Action", value: "action" },
        ],

        selectedDoctor: {
            id: null,
            display_text: 'All'
        },

        filter: {
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
        }
    }),
    created() {
        this.$store.dispatch('appointment/getAppointments', {...this.filter})
    },
    methods: {
        searchAppointments() {
            let filterData = {...this.filter}
            if (this.selectedDoctor != null && this.selectedDoctor.id != null) {
                filterData.doctor_id = this.selectedDoctor.id
            }
            this.$store.dispatch('appointment/getAppointments', filterData)
        },

        printInvoice(id) {
            this.$router.push(`/appointment-invoice/${id}`);
        },

        resetForm() {
            this.selectedDoctor = {id: null, display_text: 'All'}
            this.filter.fromDate = new Date().toISOString().substr(0, 10)
            this.filter.toDate = new Date().toISOString().substr(0, 10)
        }
    }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
    font-size: 18px !important;
    top: -3px !important;
}
.v-icon.v-icon[data-v-1f38b4e5] {
    font-size: 14px !important;
    top: 0px !important;
}
.caption {
    line-height: 1 !important;
}
</style>